@font-face {
  font-family: PeydaWeb;
  src: url("../fonts/Peyda-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: PeydaWeb;
  src: url("../fonts/peyda-light.ttf");
}

html,
body {
  font-family: PeydaWeb;
}

#loader_body .loader {
  position: absolute;
  display: none;
}

#loader_body .loader:first-child {
  width: 100vw;
  position: fixed !important;
  height: 100vh;
  z-index: 9999999999;
  background: rgb(0 0 0 / 20%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  left: 0;
  top: 0;
  display: block !important;
}

#loading {
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 9999999999;
  background: rgb(0 0 0 / 20%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  left: 0;
  top: 0;
}

.loading {
  margin: auto;
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  z-index: 99999999999;
}

.loader img {
  width: 200px;
  height: 200px;
  margin: auto;
  position: fixed;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  z-index: 99999999999;
}

.pointer {
  cursor: pointer;
}

th,
td {
  text-align: center !important;
}

button {
  border: none;
  background-color: unset;
  cursor: pointer;
}

.text-primary {
  color: #1890ff;
}

/* button.ant-pagination-item-link {
    transform: scale(-1);
} */
.ant-form-item-label {
  text-align: right !important;
  direction: ltr;
}

.ant-modal-centered .ant-modal {
  text-align: right;
}

.ant-modal-close {
  right: inherit;
  left: 0;
}

.ant-select-item-option-content {
  text-align: right;
}
.ltr {
  direction: ltr;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding: 0;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  right: unset !important;
  left: 0 !important;
}
.ant-menu-submenu-title {
  padding-right: 15px !important;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  text-align: right;
  font-weight: 600;
  color: #8c8c8c;
}
.custom_price {
  border: 1px solid #6666;
  border-radius: 15px;
  padding: 10px;
}
.nestable-item div {
  position: relative;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid rgb(46 153 153);
  background: rgb(249, 250, 250);
  cursor: pointer;
  border-radius: 5px;
}
.danger {
  color: #ff0000;
}
.ant-select-clear {
  opacity: 1 !important;
}
.ant-select-selector {
  min-height: 40px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important ;
}
.ant-btn-success {
  color: #fff;
  border-color: #24a247;
  background: #24a247;
}
.ant-btn-success:hover {
  color: #fff;
  border-color: #4dd072;
  background: #4dd072;
}
.home-editor {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  max-height: 400px;
}

.ant-input-password {
  border-radius: 6px;
  height: 40px;
  display: flex;
}
.ant-input-password input {
  height: 30px;
}
